/* This targets the outermost container. */
.quill {
    border-radius: 5px;
    box-shadow: inset 0px 3px 5px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

/* This targets the toolbar. */
.ql-toolbar.ql-snow {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

/* This targets the editor area. */
.ql-container.ql-snow {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.ql-snow .ql-tooltip {
    position: static;
}

.ql-snow .ql-tooltip[data-mode=link]::before {
    content: "lien:"
}

/* This targets the hyperlink dialog */
.ql-tooltip.ql-editing {
    position: static;
    padding-right: 8px;
    padding-left: 8px;
}

/* targets the new hyperlink dialog url input text */
.ql-snow .ql-tooltip input[type=text] {
    width: calc(100% - 50px);
}

/* targets text before url in update hyperlink dialog */
.ql-snow .ql-tooltip::before {
    content: "";
    margin: 0;
}

/* targets url in update hyperlink dialog */
.ql-snow .ql-tooltip a.ql-preview {
    width: calc(100% - 60px);
    max-width: calc(100% - 60px);
}

/* targets edit button in update hyperlink dialog */
.ql-snow .ql-tooltip a.ql-action::after {
    content: "";
    display: inline-block;
    background-image: url("./img/edit.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 28px;
    height: 16px;
    margin: 4px 0 0 8px;
    vertical-align: sub;
}

/* targets remove button in update hyperlink dialog */
.ql-snow .ql-tooltip a.ql-remove::before {
    content: "";
    display: inline-block;
    background-image: url("./img/delete.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 16px;
    height: 16px;
    vertical-align: sub;
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    content: "";
    display: inline-block;
    background-image: url("./img/save.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 16px;
    height: 16px;
    vertical-align: sub;
}